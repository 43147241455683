import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function Content({lang, type}) {
  return (
    <StaticQuery
      query={graphql`
    query {
      allMarkdownRemark
        {
          edges {
          node {
            html
            frontmatter {
            lang
            type}
          }
        }
        }
      }
  `}

render={data => (
            <div>
                {data.allMarkdownRemark.edges.map(({ node }) => {

                  var display = false;
                  // filter for specific content attributes
                  if (lang === node.frontmatter.lang && type === node.frontmatter.type) {
                    display = true;
                  }

                  return (
                    <div>
                    {display &&
                    <div
                        dangerouslySetInnerHTML={{__html: node.html}}
                    />}
                    </div>
                  )
                })}
            </div>
        )}

    />
  )
}
